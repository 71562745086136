<template>
  <div class="mobile-nav" :class="{menuOpen:this.$store.state.layout.mobileMenu}" >

    <div class="mobile-nav-header">
      <router-link class="brand" v-if="state === states.STATE_DEFAULT" :to="{name:'main_page'}">Snappy</router-link>
      <div class="back" v-else v-on:click="state = states.STATE_DEFAULT"></div>
      <div class="close" v-on:click="close()"></div>
    </div>
    <div class="mobile-nav-body">
      <div v-if="state === states.STATE_DEFAULT">
        <ul class="mobile-nav-list" v-if="$auth.check() === true">
          <li>
            <a href="#" v-on:click="state = states.STATE_PROFILE" class="has-subnav">Мой профиль</a>
          </li>
          <li>
            <router-link :to="{name:'order.area'}" v-on:click="toggle">Мои заказы</router-link>
          </li>
          <li>
            <router-link v-if="isPhotographer()" :to="{name:'photographer_profile', params:{id:$auth.user().id}}" v-on:click="toggle">Моя страница</router-link>
          </li>
        </ul>
        <ul class="mobile-nav-list">
          <li>
            <router-link :to="{name:'main_page'}">{{ $t('menu.home_page') }}</router-link>
          </li>
          <li>
            <router-link :to="{name:'about'}">{{ $t('menu.about_us') }}</router-link>
          </li>
          <!--li>
              <a href="#">{{$t('menu.price')}}</a>
          </li-->
          <li>
            <router-link :to="{name:'for_photographers'}">{{ $t('menu.photographers') }}</router-link>
          </li>
          <li>
            <a href="https://t.me/snappy_me" target="_blank">{{ $t('menu.support') }}</a>
          </li>
          <!--li>
              <a href="#">{{$t('menu.blog')}}</a>
          </li-->
        </ul>
      </div>
      <div v-if="state === states.STATE_PROFILE">
        <p class="h2">Профиль</p>
        <ul class="mobile-nav-list" v-if="isPhotographer()">
          <li>
            <router-link :to="{name:'personal.area'}" v-on:click="toggle">Персональные данные</router-link>
          </li>
          <li>
            <router-link :to="{name:'payment.area'}" v-on:click="toggle">Настрока выплат</router-link>
          </li>
          <li>
            <router-link :to="{name:'profile.area'}" v-on:click="toggle">Данные профиля</router-link>
          </li>
          <li>
            <router-link :to="{name:'secure.area'}" v-on:click="toggle">Безопасность</router-link>
          </li>
          <li>
            <router-link :to="{name:'notification.area'}" v-on:click="toggle">Оповещения</router-link>
          </li>
          <li>
            <router-link :to="{name:'photos.area'}" v-on:click="toggle">Фотографии</router-link>
          </li>
        </ul>
        <ul class="mobile-nav-list" v-else>
          <li>
            <router-link :to="{name:'personal.area'}" v-on:click="toggle">Персональные данные</router-link>
          </li>
          <li>
            <router-link :to="{name:'payment.area'}" v-on:click="toggle">Настрока оплаты</router-link>
          </li>
          <li>
            <router-link :to="{name:'profile.area'}" v-on:click="toggle">Данные профиля</router-link>
          </li>
          <li>
            <router-link :to="{name:'secure.area'}" v-on:click="toggle">Безопасность</router-link>
          </li>
          <li>
            <router-link :to="{name:'notification.area'}" v-on:click="toggle">Оповещения</router-link>
          </li>

        </ul>
      </div>
    </div>
    <div class="mobile-nav-footer">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-md-5">
          <div class="auth">
            <div class="d-flex justify-content-between align-items-center" v-if="$auth.check() === true">
              <div class="mobile-nav-user d-flex align-items-center">
                <div>
                  <div class="avatar"
                       :style="{backgroundImage: `url(${avatar['thumb-56']})`, }"></div>
                </div>
                <div>
                  <p class="status">Пользователь</p>
                  <p class="name">{{ $store.state.user.profile.name }}</p>
                </div>
              </div>
              <button class="btn btn-primary" v-on:click="logout">Выйти</button>
            </div>
            <button class="btn btn-primary btn-block" v-b-modal.modal-login v-on:click="toggle" v-else>
              Войти
            </button>
          </div>
        </div>
        <div class="col">
          <div class="row align-items-center">
            <div class="col">
              <select-language v-if="$store.state.layout.width < 1119"></select-language>
              <select-currency></select-currency>
            </div>
            <div class="col">
              <ul class="nav-social d-flex align-items-center justify-content-end">
                <li><a href="https://t.me/snappy_me" target="_blank" rel="nofollow">telegram</a></li>
                <li><a href="https://wa.me/" target="_blank" rel="nofollow">whatsapp</a></li>
                <li><a href="https://www.youtube.com/channel/UCX-SfQrRNA-GShfR99t63Kw/" target="_blank" rel="nofollow">youtube</a></li>
                <!--li><a href="https://www.instagram.com/snappy_photoshoot/" target="_blank" rel="nofollow">instagram</a></li-->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const
    STATE_DEFAULT = 'default',
    STATE_PROFILE = 'profile';

import SelectLanguage from "./SelectLanguage";
import {mapGetters} from 'vuex';

export default {
  name: "MobileMenu",
  // props: ['isSHow'],
  components: {
    SelectLanguage
  },

  data() {
    return {
      states: {
        STATE_DEFAULT: STATE_DEFAULT,
        STATE_PROFILE: STATE_PROFILE,
      },
      state: STATE_DEFAULT,
    }
  },
  computed: {
    ...mapGetters({
      avatar: 'user/avatar'
    })
  },
  methods: {
    logout() {
      this.$auth.logout();
      this.$store.commit('user/logout');
    },
    toggle() {
      this.$store.state.layout.mobileMenu = !this.$store.state.layout.mobileMenu;
      document.body.classList.toggle("menu-open");
    },
    close() {
      this.$store.state.layout.mobileMenu = false;
      document.body.classList.remove("menu-open");
    }
  }
}
</script>