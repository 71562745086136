<template>
  <!--    photographer-item-map-search-->
  <div class="photographer-item" :class="{'photographer-item-map-search':is_map, 'photographer-item-search':!is_map}">
    <div class="d-flex" :class="{'flex-column flex-xl-row':is_map, 'flex-column flex-md-row flex-xl-column':!is_map}">
      <div class="photographer-item-slider">
        <carousel :navText="['prev','next']" :loop="true" :items="1" :nav="true" :dots="true" :autoplay="true" :autoplayTimeout="2500">
          <div class="slide" :style="{backgroundImage: `url(${header.path.profile})`}" v-for="header in user.headers"></div>
        </carousel>
        <div class="favourite-button pointer" v-on:click="addFavorite(user)" :class="{active:user.profile.is_favorited}"></div>
      </div>
      <div class="d-flex flex-column w-100">
        <div class="d-flex align-items-center user">
          <div>
            <div class="avatar" :style="{backgroundImage: `url(${user.personal.avatars['thumb-166']})`}"></div>
          </div>
          <div class="name">
            <div>{{ getName(user.personal) }}</div>
            <div v-if="!user.is_busy" class="status free">• Сейчас свободен</div>
          </div>
        </div>
<!--        <div class="dates">-->
<!--          <ul class="no-list">-->
<!--            <li><button>20 июля</button></li>-->
<!--            <li><button>21 июля</button></li>-->
<!--            <li><button>+еще</button></li>-->
<!--          </ul>-->
<!--        </div>-->
        <div class="info">
          <div class="row justify-content-between">
            <!--div class="col-12 col-md-6">
                <div class="photos">{{user.personal.photosessions}} фотоссесии</div>
            </div-->
            <div class="col-12 col-md-6">
              <div class="camera">{{ $t('lists.equipment.' + user.profile.equipment[0]) }}</div>
            </div>
          </div>
        </div>
        <ul class="tags no-list d-none d-md-block">
          <li v-for="service in user.profile.additional_services">{{ $t('lists.additional_services.' + service) }}</li>
        </ul>
        <div class="photographer-bottom d-flex mt-auto justify-content-between align-items-center mt-auto mt-auto">
          <div>
            <button class="btn btn-sm btn-primary btn-order" v-on:click="$root.$emit('create-order', user)">
              Заказать<span
                :class="{'d-none d-md-inline':is_map, 'd-none d-md-inline d-xl-none':!is_map}"> съемку</span></button>
          </div>
          <div>
            <div class="price text-right">
              {{ user.price | number('0,0', {thousandsSeparator: ' '}) }}
              <span><i class="rub">Р</i></span>
              <span v-if="duration">/ {{ $t('lists.time-choose.' + duration) }}</span>
            </div>
            <div class="muted p-xs">30% оплата при брони</div>
          </div>
          <!--div>
              <div class="rating">{{user.personal.rating.avg}} <span class="d-none d-xl-inline">({{user.personal.rating.count}})</span></div>
          </div-->
        </div>
      </div>
    </div>
    <router-link :target="openInNewPage ? '_blank' : '_self'"
                 :to="{name:'photographer_profile',params:{id:user.personal.id}, query:{...$route.query}}"
                 class="photographer-item-link"></router-link>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import SearchApi from "./services";

let api = undefined;
export default {
  name: "SearchPhotographer",
  components: {carousel},
  props: {
    duration: {
      type: String,
      default: ''
    },
    is_map: {
      type: Boolean,
      default: false
    },
    openInNewPage: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object
    }
  },
  mounted() {
    api = new SearchApi();
  },
  methods: {
    addFavorite(user) {
      if (this.$auth.check() !== true) {
        this.$bvModal.show('modal-login');
        return;
      }
      api
          .toggleFavorite(user.personal.id)
          .then((res) => {
            user.profile.is_favorited = res.data.data.is_favorited;
          })
    },
  }
}
</script>

<style scoped>

</style>