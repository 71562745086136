<template>
    <div class="">
        <div class="account-separator line"></div>
        <div>
            <p class="h2" style="float: left">{{$t('lk.photos_area.albums.number')}}{{number + 1}} </p>
            <a v-b-toggle="'collapse-'+album.id" class="album-collapse-button">
                <span class="when-opened">{{$t('lk.photos_area.albums.hide')}}</span>
                <span class="when-closed">{{$t('lk.photos_area.albums.show')}}</span>
            </a>
            <div class="clearfix"></div>
        </div>
        <b-collapse :id="'collapse-'+album.id" class="" visible>
            <div class="form-group">
                <label>{{$t('lk.photos_area.albums.name')}}</label>
                <div class="input-group album-name-field">
                	<input type="text" class="form-control form-control-sm" :placeholder="$t('lk.photos_area.albums.name')" v-model="album.name" maxlength="30">
                	<div class="input-group-append" v-if="album.id">
	                	<button class="btn btn-primary">Сохранить</button>
                	</div>
                </div>
            </div>
            <div class="form-group">
                <label>{{$t('lk.photos_area.albums.category')}}</label>
                <tag v-model="album" target="categories" 
                	:with-select="true" 
                	:select-list="$store.state.list.categories"
                	:limit="5" 
                	:placeholder="$t('lk.photos_area.albums.category_placeholder')" 
                	:subText="$t('lk.photos_area.albums.category_info')"></tag>
            </div>
            <b-button variant="secondary" block class="btn-uppercase" v-show="!album.id" v-on:click="save()">{{$t('lk.photos_area.albums.add_photos')}}</b-button>
            <div v-show="album.id">
                <div class="photos-uploader">
                    <div class="row">
                        <div class="col-12 col-md-4" v-show="album.images.length <= 20">
                            <uploader ref="uploader" reference="album" :url="'/personal/profile/album/'+album.id+'/image'"></uploader>
                        </div>
                        <div class="col-12 col-md-4" v-for="(image,key) in album.images">
                            <div class="photo" :style="{backgroundImage: `url(${image.path.small_preview})`}">
                                <div class="main" v-if="image.is_cover">
                                    <button class="btn btn-primary btn-block">{{$t('lk.photos_area.albums.main')}}</button>
                                </div>
                                <div class="hover">
                                    <div class="delete-photo" v-on:click="remove(image, key)">{{$t('lk.photos_area.delete')}}</div>
                                    <button v-if="!image.is_cover" class="btn btn-outline-primary btn-block" v-on:click="setMain(image)">{{$t('lk.photos_area.albums.choose_main')}}</button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <button class="btn btn-secondary btn-text-light btn-uppercase btn-block" v-on:click="removeAlbum(album)">{{$t('lk.photos_area.albums.delete_album')}}</button>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import Uploader from "./Uploader";
import Tag from "./Tag";
import Api from '../../service'
import {TheMask} from 'vue-the-mask'

let api = undefined;
export default {
    name: "Album",
    components: {Tag, Uploader,TheMask},
    props: {
        album: {
            type: Object,
            required: true
        },
        number:{
            type: Number,
            required: true
        }
    },
    data() {
        return {
            collapsed: false
        }
    },
    watch: {},
    mounted() {
        api = new Api();
        this.watch();
    },
    methods: {
        watch() {
            let self = this;
            let upload = this.$refs.uploader.$refs.album.dropzone;
            upload.on("success", function (file, res) {
                self.album.images.push(res.data);
            });
        },
        setMain(image){
            let covers = this.album.images.filter(image => image.is_cover === true);
            if(covers.length > 0){
                covers.forEach(cover => cover.is_cover = false);
            }
            api
            .setCover(this.album.id, image.id)
            .then(()=>{
                image.is_cover = true;
            })
        },
        removeAlbum(album){
            api
            .removeAlbum(this.album.id)
                .then((res) => {
                    this.$root.$emit('removeAlbum', album);
                })
                .catch((error) => {
                    this.$root.$emit('error', error.response.data.message);
                })
        },
        remove(image, key){
            api
            .removeImage(this.album.id, image.id)
                .then((res) => {
                    this.album.images.splice(key, 1)
                })
                .catch((error) => {
                    this.$root.$emit('error', error.response.data.message);
                })
        },
        save() {
            if (this.album.name.length <= 1) {
                return
            }
            if (this.album.categories.length < 1) {
                return
            }
            if (this.album.id) {
                api
                    .updateAlbum(this.album)
                    .then((res) => {

                    })
                    .catch((error) => {
                        this.$root.$emit('error', error.response.data.message);
                    })
                ;
            } else {
                api
                    .createAlbum(this.album)
                    .then((res) => {
                        this.album.id = res.data.data.id;
                        this.$forceUpdate();
                        this.$root.$emit('successful', 'Альбом создан');
                    })
                    .catch((error) => {
                        this.$root.$emit('error', error.response.data.message);
                    })
            }
        }

    }
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

.card {
    border: none;
}
</style>