<template>

  <div class="main-content flex-shrink-0 photographer-page">
    <ChangePhone></ChangePhone>
    <OrderInfoModal></OrderInfoModal>
    <PaymentForm :config="paymentDetails" v-if="paymentDetails.type === 'form'"></PaymentForm>
    <div class="photographer-photos-slider">
      <carousel :navText="['prev','next']"
                :loop="true"
                :autoplay="true"
                :autoplaySpeed="2000"
                :autoplayTimeout="3000"
                :responsive="{0:{items:1,dots:true,nav:false},768:{items:2,dots:true,nav:true},1200:{items:3,dots:false,nav:true}}"
                v-if="user && user.headers.length > 2">
				<!--Если видео <div class="slide slide-video" style="background-image: url(https://snappy.sealed.company/storage/35/conversions/4-2-profile.jpg);"></div> -->
        <div class="slide" :style="{backgroundImage: `url(${header.path.profile})`}" v-for="header in user.headers"></div>
      </carousel>
    </div>

    <div class="container">
      <div class="row" v-if="user && user.profile">
        <div class="col-12 col-xl-8">
          <div class="photographer-info">
            <div class="avatar like" :style="{
                            backgroundImage: `url(${user.personal.avatars['thumb-166']})`,
                            }"></div>
            <div class="content">
              <div class="name-status d-flex flex-column flex-md-row align-items-md-end justify-content-md-between">
                <div>
                  <p class="name">{{ getName(user.personal) }}</p>
                </div>
                <div>
                  <p class="status free">• Сейчас свободен</p>
                </div>
              </div>
              <!--button class="btn btn-primary-light btn-icon icon-star add-favourite">{{user.personal.rating.avg}}</button-->
              <div class="about">
                <!--                                {{user.profile.user_location.country[$store.state.user.options.locale]}},-->
                <p class="location"> {{ user.profile.user_location.city[$store.state.user.options.locale] }}</p>
                <!--p class="photos">{{user.personal.photosessions}} выполненных фотосессий</p-->
                <p class="registered">{{ $t('base.registered_text') }}
                  {{ user.personal.registered | moment("from", "now") }}</p>
              </div>
            </div>
          </div>
          <!--button class="write-photographer btn btn-white btn-md btn-block">
              <div class="icon icon-comment"></div>
              {{$t('base.message_to_photographer')}}
          </button-->

          <div class="line"></div>

          <div class="photographer-book">
            <p class="h1">{{ $t('base.free_dates') }}</p>
            <div class="shadowed-block">
              <div class="form-group">
                <!--label>Город съемки</label-->
								<ul class="city-select no-list">
									<li
											v-for="city in calendar_data.cities"
											v-bind:key="city.id"
											v-on:click="()=>{calendar_data.city = city; $refs.citySelect.hide()}"
											:class="{active:city===calendar_data.city}">
										{{ city[$store.state.user.options.locale] }}
									</li>
								</ul>
              </div>
              <calendar-block :languages="languages" :calendar="calendar_data.data"
                              :photographer="user.personal"></calendar-block>
            </div>
          </div>

          <div class="line"></div>

          <div class="photographer-about">
            <p class="h1"> {{ $t('base.about_photographer') }}</p>
            <div class="text">
              {{ user.profile.about }}
            </div>
          </div>

          <div class="line"></div>

          <div class="photographer-params">
            <p class="h1">{{ $t('base.information') }}</p>
            <p class="title">{{ $t('base.equipment') }}</p>
            <ul class="no-list">
              <li v-for="equipment in user.profile.equipment">{{ $t('lists.equipment.' + equipment) }}</li>
            </ul>
            <!--p class="title">Готовность фото</p>
            <ul class="no-list">
                <li v-for="(photo_ready, index) in user.profile.photo_readiness">{{$t('lists.photo_ready.'+index)}} - {{ $tc('lists.photo_ready_days', photo_ready) }}</li>
            </ul-->
            <p class="title">{{ $t('profile.languages') }}</p>
            <ul class="no-list">
              <li v-for="language in user.profile.languages">{{ $t('languages.' + language) }}</li>
            </ul>
            <p class="title">{{ $t('base.additional_service') }}</p>
            <ul class="no-list">
              <li v-for="service in user.profile.additional_services">{{ $t('lists.additional_services.' + service) }}
              </li>
            </ul>
            <p class="title">Работаю</p>
            <ul class="no-list">
              <li v-for="location in user.profile.locations">{{ $t('lists.locations.' + location) }}</li>
            </ul>
          </div>
          <div class="line"></div>

          <div class="photographer-photos" v-if="user.albums.length > 0">
            <p class="h1">{{ $t('base.photos') }}</p>
            <div class="categories-filter narrow">
              <div class="categories-filter-all">
                <div class="pointer" v-on:click="albumTag = 'all'" :class="{active:albumTag === 'all'}">
                  {{ $t('categories.all') }}
                </div>
              </div>
              <div class="categories">
                <ul class="no-list">
                  <li v-for="category in categories">
                    <div class="pointer" v-on:click="albumTag = category" :class="{active:albumTag === category}">
                      {{ $t('categories.' + category) }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4 d-md-block" v-for="album in getAlbums()">
                <div class="photos-card" v-b-modal="'modal-album-'+album.id" v-if="album.images[0]">
                  <div class="photo" :style="{
                                                        backgroundImage: `url(${getCover(album)})`,
                                                        }">
                    <!--                                    <div class="photo" :style="{-->
                    <!--                                                        backgroundImage: `url(${album.images[0].path.medium_preview})`,-->
                    <!--                                                        }">-->

                  </div>
                  <div class="hover">
                    <div class="name">{{ album.name }}</div>
                    <div class="count">{{ album.images.length }} фотографий</div>
                  </div>
                </div>
                <b-modal :id="'modal-album-'+album.id" size="xl" centered hide-header hide-footer>
                  <div class="modal-header">
                    <div class="modal-brand"></div>
                    <div class="close" @click="$bvModal.hide('modal-album-'+album.id)"></div>
                  </div>
                  <div class="modal-xl-content album-gallery">
                    <carousel :navText="['prev','next']"
                              :loop="true"
                              :center="true"
                              :autoWidth="true"
                              :dots="false"
                              :responsive="{0:{nav:false},768:{nav:true},1200:{nav:true}}"
                    >
                      <div class="slide" v-for="image in album.images">
                        <img :src="image.resized_full" class="img-fluid">
                      </div>

                    </carousel>
                  </div>
                  <div class="album-sharing">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="pointer user d-flex align-items-center"
                           @click="$bvModal.hide('modal-album-'+album.id)">
                        <div>
                          <div class="avatar" :style="{
                                                    backgroundImage: `url(${user.personal.avatars['thumb-166']})`,
                                                    }"></div>
                        </div>
                        <div>
                          <p class="name">{{ getName(user.personal) }}</p>
                          <p class="comment">{{ album.images.length }} фотографий</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <!--div>
                            <div class="share"></div>
                        </div-->
                        <!--                                                <div>-->
                        <!--                                                    <div class="btn btn-primary-light btn-icon icon-heart like">353</div>-->
                        <!--                                                </div>-->
                      </div>
                    </div>
                  </div>
                </b-modal>
              </div>

            </div>
            <!--                        <a href="#" class="btn btn-secondary btn-lg btn-block btn-text-light btn-text-bigger btn-uppercase" v-on:click="albumLimit += 3">Показать больше</a>-->
          </div>

          <div class="feedbacks" v-if="reviews.length > 0">
            <p class="h1">Отзывы</p>
            <div v-for="review in reviews">
              <div class="feedback">
                <div class="content">
                  <div class="avatar" :style="{
                                    backgroundImage: `url(${review.client.avatars['thumb-166']})`,
                                    }"></div>
                  <div class="info">
                    <div class="d-flex w-100 justify-content-between">
                      <div class="role">Клиент</div>
                      <div class="date">{{ review.created_at | moment("from", "now") }}</div>
                    </div>
                    <div class="d-flex w-100 justify-content-between justify-content-md-start">
                      <div class="name">{{ getName(review.client) }}</div>
                    </div>
                  </div>
                  <div class="text">
                    {{ review.text }}
                  </div>
                </div>
              </div>
              <div class="feedback reply" v-for="answer in review.answers">
                <div class="content">
                  <div class="avatar" :style="{
                                    backgroundImage: `url(${answer.author.avatars['thumb-166']})`,
                                    }"></div>
                  <div class="info">
                    <div class="d-flex w-100 justify-content-between">
                      <div class="role green">{{ $t('lists.roles.' + answer.author.role) }}</div>
                      <div class="date">{{ answer.created_at | moment("from", "now") }}</div>
                    </div>
                    <div class="d-flex w-100 justify-content-between justify-content-md-start">
                      <div class="name">{{ getName(answer.author) }}</div>
                      <div class="status">{{ $t('lists.roles.' + answer.author.role) }}</div>
                    </div>
                  </div>
                  <div class="text">
                    {{ answer.text }}
                  </div>
                </div>
              </div>
            </div>

            <div
                v-if="reviewOptions.offset +  reviewOptions.limit < reviewOptions.total"
                class="btn btn-secondary btn-lg btn-block btn-text-light"
                v-on:click="reviewOptions.offset += reviewOptions.limit">{{ $t('base.show_more') }}
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4 d-none d-xl-block">
          <desktop-order :order="order"
                         :price="price"
                         :order-data="orderData"
                         ref="desktop"
                         :calendar="calendar"></desktop-order>
          <div class="sidebar-block">
            <ul class="sidebar-nav no-list">
              <li>
                <div class="sidebar-nav-icon icon-heart pointer" :class="{active:user.profile.is_favorited}"
                     v-on:click="addFavorite">
                  {{ user.profile.is_favorited ? $t('profile.remove_favorite') : $t('profile.add_favorite') }}
                </div>
              </li>
              <!--li>
                  <div class="sidebar-nav-icon icon-gift">{{$t('profile.give')}}</div>
              </li-->
            </ul>
          </div>
        </div>
      </div>
    </div>

    <mobile-order ref="mobile" :order="order" :order-data="orderData" :user="user" :calendar="calendar" :promo_code="promo_code"></mobile-order>

    <div class="photographers-slider" v-if="photographers.length > 0">
      <div class="container">
        <p class="h1">Похожие фотографы</p>
        <carousel :nav="true" :navText="['prev','next']" :loop="true"
                  :responsive="{0:{items:1,dots:true},768:{items:2,dots:true},1200:{items:3,dots:false}}"
                  v-if="photographers.length > 0">

          <router-link :to="{name:'photographer_profile',params:{id:photographer.user.id}}"
                       class="photographer-card card" v-bind:key="photographer.user.id"
                       v-for="photographer in photographers">
            <div class="card-header">
              <div class="avatar" :style="{
                            backgroundImage: `url(${photographer.user.avatars['thumb-166']})`,
                            }"></div>
              <div class="info">
                <!--div class="rating">{{photographer.user.rating.avg}}</div-->
                <div class="name">
                  {{ getName(photographer) }}
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="tags no-list">
                <li v-for="service in photographer.user.additional_services">
                  {{ $t('lists.additional_services.' + service) }}
                </li>
              </ul>
              <div class="line"></div>
              <div class="camera" v-if="photographer.user.equipment && photographer.user.equipment.length > 0">
                {{ $t('lists.equipment.' + photographer.user.equipment[0]) }}
              </div>
              <!--div class="photos">{{photographer.user.photos}} фотоссесии</div-->
            </div>
            <div class="card-footer">
              <div class="btn btn-secondary btn-block">Подробнее</div>
            </div>
          </router-link>

        </carousel>
      </div>
    </div>

    <div class="front-app">
      <div class="container">
        <div class="app-block">
          <div class="row align-items-center">
            <div class="col-12 col-md-6">
              <div class="title">{{ $t('base.store') }}</div>
              <div class="description lead">{{ $t('base.store_text') }}
              </div>
            </div>
            <div class="col-12 col-md-6">
              <img src="/assets/images/app.png" class="screen img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {BModal} from 'bootstrap-vue';
import carousel from 'vue-owl-carousel';
import ProfileApi from './service';
import MainApi from '../main/services';
import * as lang from "vuejs-datepicker/src/locale";

import moment from 'moment';
import MobileOrder from "./MobileOrder";
import DesktopOrder from "./DesktopOrder";
import CalendarBlock from "../widgets/CalendarBlock";
import OrderInfoModal from "@/components/modals/OrderInfoModal";
import ChangePhone from "@/components/lk/components/widget/ChangePhone";
import PaymentForm from "@/components/payment/PaymentForm.vue";

let api = undefined;
let startDurationIndex = 1;
export default {
  name: 'user',
  data() {
    return {
      paymentDetails:{},
      id: this.$route.params.id,
      user: undefined,
      languages: lang,
      categories: [],
      albumLimit: 3,
      albumTag: 'all',
      timer: 1, //таймер прошгрузки галрей ибо паралельно либа умирает
      loadModules: {
        profile: false,
        reviews: false,
      },
      order: {
        photographer: {
          id: this.$route.params.id
        },
        city: {
          id: undefined
        },
        promo_code: '',
        durationIndex: startDurationIndex,
        count_people: 'min',
        start_date: '',
        is_full_day: false,
        duration: moment().startOf('day').add(this.getOrderDurations()[startDurationIndex].value, 'minutes'),
        equipment: [],
        price: 1,
        services: [],
        locations: [],
        photosession_type: '',
      },
      price: {
        price: 0,
        discount_sum: 0,
        full_price: 0
      },
      orderData: {
        hours: [],
        start_hours: {
          from: '',
          to: ''
        },
        equipment: [],
        additional_services: [],
        photosession_types: [],
      },
      reviews: [],
      reviewOptions: {
        offset: 0,
        limit: 3,
        total: undefined
      },
      calendar: {
        is_main: true,
        calendar: [],
      },

      calendar_data: {
        data: {
          is_main: true,
          calendar: [],
        },
        cities: [],
        city: undefined,
      },

      ordersInMonth: [],
      photographers: []
    };
  },
  computed: {},
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    next();
  },
  watch: {
    id: {
      handler: function () {
        this.$root.$emit('go-top');
        window.location.reload();
      },
      deep: true
    },
    loadModules: {
      handler: function (newValue) {
        this.$root.$emit('load-modules', newValue);
      },
      deep: true
    },
    "reviewOptions.offset": {
      handler: function (newValue) {
        if (newValue) {
          this.getReviews();
        }
      },
      deep: true
    },
    "order.start_date": {
      handler: function (newValue) {
        if (newValue) {
          let minutes = moment(newValue).format('mm');
          if(minutes > 15 && minutes <= 45) {
            minutes = 30;
          }else{
            minutes = 0;
          }
          this.order.start_date.setMinutes(minutes);
        }
      },
      deep: true
    },
    "calendar_data.city": {
      handler: function (newValue) {
        if (newValue) {
          this.getTownCalendar(newValue);
        }
      },
      deep: true
    },

  },
  components: {PaymentForm, ChangePhone, OrderInfoModal, CalendarBlock, DesktopOrder, MobileOrder, carousel, BModal},
  methods: {
    loadOrderParams() {
      api
          .getOrderData(this.id)
          .then((res) => {
            this.orderData.equipment = res.data.data.equipment;
            this.orderData.photosession_types = res.data.data.photosession_types;
            this.orderData.additional_services = res.data.data.additional_services;
            this.orderData.locations = res.data.data.locations;
            this.order.equipment = res.data.data.equipment;
            this.order.duration = moment().startOf('day').add(this.getOrderDurations()[startDurationIndex].value, 'minutes');
            this.order.photosession_type = res.data.data.photosession_types.general.value;
            if (this.$route.query.filters) {
              this.getParamsFromQuery();
            }
            this.getFullPrice();
            this.getOrderCalendar();
          });

    },
    getFullPrice() {
      if (!this.loading) {
        if (this.order.is_full_day) {
          this.order.price = this.orderData.photosession_types.general.matrix.day;
        } else {
          this.loading = true;
          api
              .getPrice(this.order.photographer.id, this.order.photosession_type, moment(this.order.duration).diff(moment().startOf('day'), 'minutes'))
              .then((data) => {
                // this.order.price = data.data.data.price;
                //TODO как это получилось!?
                this.price = data.data.data;
                this.$forceUpdate();
                this.loading = false;
              })
        }
      }

    },
    addFavorite() {
      if (this.$auth.check() !== true) {
        this.$bvModal.show('modal-login');
        return;
      }
      api
          .toggleFavorite(this.id)
          .then((res) => {
            this.user.profile.is_favorited = res.data.data.is_favorited;
            if (res.data.data.is_favorited) {
              this.user.profile.favorites += 1;
            } else {
              this.user.profile.favorites -= 1;
            }
          })
    },
    loadCategories(albums) {
      let categories = [];
      albums.forEach((album) => {
        album.categories.forEach((category) => {
          categories.push(category)
        })
      });
      this.categories = ((categories.filter((a, b) => categories.indexOf(a) === b)));
    },
    getCover(album) {
      let covers = album.images.find(image => image.is_cover === true);
      if (covers !== undefined && covers.length > 0) {
        return covers[0].path.medium_preview
      }
      return album.images[0].path.medium_preview
    },
    load() {
      api
          .profile(this.id)
          .then((res) => {

            this.user = res.data.data;

            if (this.user.personal.role === 'client') {
              this.$router.push({path: '/'});
            }

            //новая выпаадшка городов!!!! как же я задобался :)
            this.calendar_data.cities = [];
            this.calendar_data.city = undefined;
            this.calendar_data.cities.push(this.user.profile.user_location.city);
            this.user.profile.towns.forEach(town => this.calendar_data.cities.push(town))
            this.calendar_data.city = this.user.profile.user_location.city;

            this.loadCategories(this.user.albums);
            this.loadModules.profile = true;
            let self = this;
            setTimeout(() => {
              self.$refs.desktop.$on('book', () => {
                self.booking()
              });
              self.$refs.mobile.$on('book', () => {
                self.booking()
              });
            }, 100);
          });
      this.getPhotographer();
      this.getReviews();
    },
    getAlbums() {
      let albums = this.user.albums;
      if (this.albumTag !== 'all') {
        albums = [];
        albums = this.user.albums.filter(album => album.categories.indexOf(this.albumTag) !== -1);
      }
      return albums.slice(0, this.albumLimit);
    },
    getPhotographer() {
      let mainApi = new MainApi();
      mainApi.photographer()
          .then((res) => {
            this.photographers = res.data.data;
            this.loadModules.photographer = true;
            var timerInterval = setInterval(() => {
              if (this.timer > 0) {
                this.timer = this.timer - 1;
              } else {
                clearInterval(timerInterval);
              }
            }, 150);
          });
    },
    getReviews() {
      api.reviews(this.id, this.reviewOptions.limit, this.reviewOptions.offset)
          .then((res) => {
            if (res.data.data.reviews.length > 0) {
              res.data.data.reviews.forEach((review) => {
                this.reviews.push(review)
              })
            }
            this.reviewOptions.total = res.data.data.total;
            this.loadModules.reviews = true;
          });
    },
    getTownCalendar(choiceCity) {
      api
          .getCalendar(
              this.id,
              choiceCity.id)
          .then((res) => {
            this.calendar_data.data = res.data.data;
          })
    },
    getOrderCalendar() {
      // this.getTown().then((city) => {
      api
          .getCalendar(
              this.id,
              this.order.city.id ?? this.$place.getCity().id)
          .then((res) => {
            this.calendar = res.data.data;
          })
      // });
    },
    booking() {
      if (this.$metrika) {
        this.$metrika.reachGoal('Booking');
      }

      this.$root.$emit('press_vk_button', 'click-button-find', 36514681);
      if (this.$auth.check() !== true) {
        this.$bvModal.show('modal-login');
        return;
      } else if (!this.isPhoneVerified()) {
        this.$bvModal.show('modal-update-phone');
        return;
      } else {
        if (!this.order.city.id) {
          this.order.city.id = this.$place.getCity().id;
        }
        if (!this.order.address) {
          this.order.address = this.$place.getRawData().formatted_address;
        }

        let order = JSON.parse(JSON.stringify(this.order));
        order.start_date = moment(this.order.start_date).format('DD.MM.YYYY HH:mm');
        order.duration = moment(this.order.duration).diff(moment().startOf('day'), 'minutes');
        order.address_coordinates = encodeURIComponent(JSON.stringify(this.$place.getCoordinates() ? this.$place.getCoordinates() : {
          lat: 55.7558260,
          lng: 37.6172999
        }));
        this.$root.$emit('load-start');
        api
            .createOrder(order)
            .then((res) => {
              this.paymentDetails = res.data.data.payment_info;
              if(this.paymentDetails.type === 'redirect') {
                this.$bvModal.show('modal-before-order-redirect');
                setTimeout(() => {
                  if (res.data.data.payment_info.payment_id) {
                    window.location.replace(res.data.data.payment_info.checkout_url);
                  } else {
                    this.$router.push({name: 'order.area'});
                  }
                }, 3000);
              }
              this.$root.$emit('load-modules', true);
            })
            .catch((error) => {
              this.$root.$emit('load-modules', true);
            });
      }

    },
    clearData(value, param, paramName) {
      value.forEach((service) => {
        if (typeof this.orderData[paramName] !== "undefined") {
          let index = this.orderData[paramName].indexOf(service);
          if (index !== -1) {
            this.order[param].push(service);
          }
        }
      });
    },
    getParamsFromQuery() {
      for (let [key, value] of Object.entries(JSON.parse(this.$route.query.filters))) {
        if (value && value !== '') {
          if (key === 'duration') {
            // let durations = this.getOrderDurations();
            // durations.filter((a, b) => {
            //   if (a.value === value) {
            //     this.order.durationIndex = b;
            //   }
            // });
            this.order.duration = moment().startOf('day').add(value, 'minutes');
          } else if (key === 'start_date') {
            let date = moment(value).toDate();
            if (date instanceof Date && !isNaN(date)) {
              this.order.start_date = date;
            } else {
              this.order.start_date = moment().toDate();
            }
            setTimeout(() => {
              this.$forceUpdate();
            }, 1000);
          } else if (key === 'city_id') {
            this.order.city.id = value;
          } else if (key === 'photosession_type') {
            // this.order.photosession_type = value;
          } else if (key === 'equipment') {
            // this.order[key] = this.orderData.equipment[0]; //TODO разобраться почему нету
          } else if (Array.isArray(value)) {
            this.clearData(value, key, key === 'services' ? 'additional_services' : key);
          } else {
            this.order[key] = value;
          }
        }
      }
    },

  },
  mounted() {
    this.$root.$emit('go-top');
    api = new ProfileApi();
    api.lists();
    this.load();

    this.$root.$on('update-options', () => {
      this.$forceUpdate();
    });
    this.$refs.mobile.$on('addFavorite', () => {
      this.addFavorite();
    });
    this.loadOrderParams();
    this.$root.$on('auth', () => {
      api = new ProfileApi();
    });
    this.$root.$on('update-place', () => {
      this.getOrderCalendar();
    });


  }
};
</script>