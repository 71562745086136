// import axios from 'axios';
import Api from '../services';

export default class PersonalAreaApi extends Api {
    full() {
        return this.web.get('/');
    }

    getPersonal() {
        return this.web.get('/personal');
    }

    updatePersonal(user) {
        return this.web.post('/personal', user);
    }

    getCard() {
        return this.web.get('/personal/cards');
    }

    updateCard(cards) {
        return this.web.post('/personal/payout', cards);
    }

    getPayment() {
        return this.web.get('/personal/payment');
    }
    getNotify() {
        return this.web.get('/notifications/type/user');
    }

    toggleNotifyType(type) {
        return this.web.post('/notifications/type',{type:type});
    }

    updatePayment(payment) {
        return this.web.post('/personal/payment', payment);
    }

    getProfile() {
        return this.web.get('/personal/profile');
    }

    updateProfile(profile) {
        // let formData = new FormData();
        // formData.append('avatar', avatar);
        return this.web.post('/personal/profile', profile);
    }

    updateAvatar(avatar) {
        let formData = new FormData();
        formData.append('avatar', avatar);
        return this.web.post('/personal/profile', formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    resetPassword(password){
        return this.web.post('/personal/change-password', password);
    }

    getAlbums(){
        return this.web.get('/personal/profile/album');
    }

    createAlbum(album){
        return this.web.post('/personal/profile/album', album);
    }

    updateAlbum(album){
        return this.web.post('/personal/profile/album/'+album.id, album);
    }

    removeImage(album_id, id){
        return this.web.delete('/personal/profile/album/'+album_id+'/image/'+id);
    }

    removeAlbum(album_id){
        return this.web.delete('/personal/profile/album/'+album_id);
    }


    setCover(album_id, id){
        return this.web.patch('/personal/profile/album/'+album_id+'/image/'+id+'/cover');
    }

    getHeaders(){
        return this.web.get('/personal/profile/header');
    }

    removeHeader(id){
        return this.web.delete('/personal/profile/header/'+id);
    }

    getFavorites(){
        return this.web.get('/personal/profile/favorites');
    }

    updateTravels(city_id, travels){
        return this.web.post('/personal/profile/travels/'+city_id, {dates:travels});
    }

    getTravels(city_id){
        return this.web.get('/personal/profile/travels/'+city_id);
    }

    getCities(){
        return this.web.get('/personal/order/towns');
    }
    getOrdersBytTown(city){
        return this.web.get('/personal/order/city/'+city);
    }
    getOrders(){
        return this.web.get('/personal/order');
    }
    getNonWorkingHours(){
        return this.web.get('/personal/profile/getNotWorkingHours');
    }

    updateNonWorkingHours(hours, deleteHours){
        return this.web.post('/personal/profile/addNotWorkingHours', {data:hours, delete:deleteHours});
    }

    getOrder(uuid){
        return this.web.get('/personal/order/'+uuid);
    }
    changeStatusOrder(uuid, status){
        return this.web.post('/personal/order/'+uuid+'/change_status',{status:status});
    }
    cancel(uuid){
        return this.web.post('/personal/order/'+uuid+'/cancel');
    }
    getNewConfirmCode(key){
        return this.web.get('/personal/resend-code');
    }
    confirmPhone(code, key){
        return this.web.get('/personal/check-code?code='+code);
    }

    askVerify(){
        return this.web.post('/personal/ask_verify');
    }
}