<template>
  <b-modal id="modal-search-calendar" centered hide-header hide-footer>
    <datepicker
        class="vdp-datepicker-inline"
        :disabledDates="disableDates"
        v-model="date"
        :inline="true"
        :fullMonthName="true"
        :language="languages[$store.state.user.options.locale]"
        :mondayFirst="true">
    </datepicker>
    <button v-on:click="$bvModal.hide('modal-search-calendar')" class="btn btn-block btn-primary"> Выбрать</button>
  </b-modal>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import moment from "moment/moment";
import * as lang from "vuejs-datepicker/dist/locale";
export default {
  name: "CalendarModal",
  components: { datepicker},
  data(){
    return{
      languages: lang,
      date:moment().toDate(),
      disableDates: {
        to: moment().add(-1, 'day').endOf("day").toDate(),
      },
    }
  },
  watch:{
    date:{
      handler: function (newValue) {
        console.log(newValue);
        this.$root.$emit('change_date', newValue);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>