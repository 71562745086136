<template>
  <div class="container">
    <div class="inner-content">
      <div class="about-promo">
        <img src="/assets/images/photographers-collage.jpg" class="img-fluid">
      </div>
      <div class="front-about">
        <h1 class="h2">Присоединяйся к Snappy. Помоги клиентам найти тебя!</h1>
        <p>Snappy – сервис быстрого поиска фотографов. Помогаем твоему клиенту скорее найти и заказать у тебя съемку. На
          Snappy только проверенные фотографы из любых городов мира. Присоединяйся!<br>
          Со Snappy - клиент найдет тебя сам.</p>

        <button class="btn btn-primary" type="button" v-on:click="$root.$emit('show-login-form', 'register-photo')">
          Зарегистрироваться
        </button>

        <hr class="xl">

        <h2>Как понять нужно ли тебе на Snappy? Ответь себе, хочешь ли ты:</h2>
        <p><strong class="green">Заполнить «окна» в графике съемок:</strong></p>
        <ul>
          <li>У меня популярный Instagram, достаточно клиентов, чтобы мой график был занят min на 70-80%. Хотелось бы
            занять оставшиеся 20% времени, не отвлекаясь от творчества.
          </li>
          <li>Мне не нравится, когда внезапно «отваливается» съемка. Я планировал время, выехал в другой город или
            забронировал студию, стилиста... Хочу быстро закрыть «окно» другим клиентом.
          </li>
        </ul>
        <p><strong class="green">Быстро находить новых клиентов и договариваться о съемке.</strong> Лучше – сделать так,
          чтобы клиенты находили тебя сами:</p>
        <ul>
          <li>Вкладываю максимум сил и времени в съемки, обработку, развитие своего стиля. Хочу на 100% посвящать себя
            творчеству, делегировать привлечение клиентов (фотограф – творец, а не продавец!).
          </li>
          <li>Сложно быть самому себе SMM–щиком. И снимать много и классно, и соцсети свои развивать. Таргет, SMS, гивы,
            лайки, подписки… Не знаю, как по-другому получать заказы?
          </li>
          <li>Клиенты пишут. Но столько времени уходит на выяснение деталей, чтобы договориться о съемке. 100 одинаковых
            вопросов в день в директ. 2 съемки бы за это время провел.
          </li>
          <li>Завел анкеты на всех популярных агрегаторах фрилансеров. Но там сложно рассказать о себе, не показать
            нормально работы.
          </li>
        </ul>
        <p><strong class="green">Начать снимать и стабильно зарабатывать съемкой:</strong></p>
        <ul>
          <li>Недавно снимать стал (давно мечтал), камера есть, стиль у меня хороший. Но не знаю, что делать дальше? Как
            и где находить клиентов?
          </li>
          <li>Клиенты то есть, то снова их нет. Не понимаю, что с этим делать?</li>
        </ul>
        <p><strong class="green">Путешествовать по стране, брать съемки за рубежом:</strong></p>
        <ul>
          <li>В моем городе я уже известен, хочу снимать по всей России, в Петербурге, в Москве.</li>
          <li>Я люблю путешествовать. Заработать на съемке в путешествии и окупить отпуск было бы классно. Но как
            клиенты найдут меня там, где я всего день или несколько?
          </li>
          <li>В моей основной локации бывает не сезон, нет клиентов. С удовольствием уехал бы на это время в другой
            город или даже страну, но как там меня найдут клиенты?
          </li>
          <li>Много фотографов из Insta живут и снимают за рубежом. Мой стиль на уровне. Тоже хочу пожить и зарабатывать
            съемкой в другой стране. Не знаю пока, как там раскрутиться.
          </li>
        </ul>
        <p class="h2"><span class="green">Узнал себя хотя бы в 1 пункте?</span><br>
          Не тяни с решением вопроса – регистрируй аккаунт на Snappy</p>
        <button class="btn btn-primary" type="button" v-on:click="$root.$emit('show-login-form', 'register-photo')">
          Зарегистрироваться
        </button>

        <hr class="xl">

        <h2>Чем полезен Snappy фотографу, т.e. тебе:</h2>
        <ol>
          <li>Продвигаем тебя в том городе, где ты хочешь снимать и в то время, когда ты готов снимать</li>
          <li>Помогаем закрыть свободные «окна» в графике – даже 30 свободных минут между съемками можно выгодно
            использовать
          </li>
          <li>Делаешь экспресс-съемки в популярных локациях или гуляешь в парке после съемки и не прочь сделать 5-7
            снимков? – есть режим «Найти фотографа рядом». Находи клиентов в радиусе нескольких сотен метров от себя, не
            гуляй с камерой впустую
          </li>
          <li>В любом городе, где ты хочешь снимать, ты можешь начать привлекать клиентов. Чем популярнее город, тем
            проще найти там заказы – чем больше фотографов в городе, тем активнее он продвигается на Snappy
          </li>
          <li>Не знаешь с чего начать карьеру фотографа или как увеличить заказы? – Начни со Snappy. Твое промо Snappy
            берет на себя, с тебя – творчество и счастливые клиенты!
          </li>
          <Li>Напомним про скорую съемку, про сдачу фото клиенту, сохраним всю историю по заказу. Snappy – это личный
            кабинет фотографа и удобный календарь съемок
          </Li>
          <li>Экономим твое время на общении с клиентами. Заявка максимально проста, всю базовую информацию клиент
            узнает сам из твоего аккаунта на Snappy
          </li>
        </ol>

        <hr class="xl">

        <div class="row">
          <div class="col-12 col-md-8 col-lg-9 order-md-last">
            <p class="h2">Кто может стать фотографом на Snappy:</p>
            <ol>
              <li><strong>Профессионалы с авторским стилем – мы зовем их Guru.</strong> Закроем «окна» в графике или
                найдем клиентов в новом городе или стране
              </li>
              <li><strong>Опытные фотографы с профессиональной камерой – Super Pro.</strong> Поможем увеличить
                количество новых клиентов. Хочешь снимать в разных городах? – Тоже welcome!
              </li>
              <li><strong>Начинающие фотографы и любители мобильной съемки – Агенты Snappy.</strong> Найдем первых
                клиентов, тем, кто решил стать фотографом. И тем, кто круто снимает на мобильный, но почему-то все еще
                делает это бесплатно и только для знакомых.
              </li>
            </ol>
            <p>Нам важно одно – насколько хороши твои работы и счастливы клиенты после работы с тобой!</p>
          </div>
          <div class="col-12 col-md-4 col-lg-3 order-md-first">
            <img src="/assets/images/screen-1.jpg" class="img-fluid d-block mx-auto mb-4" width="255">
          </div>
        </div>

        <hr class="xl">

        <div class="row">
          <div class="col-12 col-md-8 col-lg-9 order-md-last">
            <p class="h2">Как начать получать заказы на Snappy:</p>
            <ol>
              <li><strong>Зарегистрируй аккаунт.</strong> Весь процесс займет 15 минут. Перейди по ссылке сюда –
                <strong>Регистрация фотографа.</strong></li>
              <li>
                <strong>В открывшемся Личном кабинете фотографа:</strong>
                <ol>
                  <li><strong>Загрузи свое фото</strong> – клиенту приятно видеть, кто будет с ним работать, и напиши
                    пару строк и себе. Картинки не принимаются.
                  </li>
                  <li><strong>Напиши как тебя зовут по имени</strong> – как клиенту и нам к тебе обращаться.</li>
                  <li><strong>Отметь город, а можно несколько городов,</strong> где ты работаешь или хочешь брать
                    съемки, в какие даты и свободные часы.
                  </li>
                  <li><strong>Выложи обязательно примеры работ (10-20 фото) в шапку профиля,</strong> еще лучше
                    несколько альбомов с фотографиями – клиенты выбирают по работам, это важно.
                  </li>
                  <li><strong>Напиши дополнительную информацию</strong> – некоторые клиенты хотят студию, макияж, разные
                    дополнительные услуги. Если ты с ними помогаешь – это еще один плюс в пользу выбора тебя.
                  </li>
                  <li><strong>Не забудь указать стоимость съемок.</strong> Snappy не призывает тебя делать скидки или
                    придерживаться каких-то определенных тарифов, указывай стоимость, по которой ты готов работать.
                  </li>
                  <li><strong>И конечно укажи свои контакты</strong> – телефон (на него придут быстрые уведомления и о
                    статусе заказа) и e-mail.
                  </li>
                </ol>
              </li>
              <li><strong>Отправь аккаунт на подтверждение модератора.</strong> – Кнопка «Оправить на проверку». Если
                все 5 пунктов выше верно заполнены, модератор подтвердит твой аккаунт в течение 24 часов (чаще это
                происходит быстрее). Модератор может связаться с тобой, чтобы попросить поправить или дополнить
                информацию. Пойми, мы должны быть уверены в том, кого рекомендуем клиентам. Ты получишь сообщение о том,
                что аккаунт подтвержден.
              </li>
              <li><strong>Готово! Твой аккаунт размещен на Snappy.</strong> Клиенты видят тебя в тех городах и в то
                время, которые ты отметил как доступные для заказа. Жди уведомления о заказе и, пожалуйста, оперативно
                подтверждай клиенту съемку.
              </li>
            </ol>
          </div>
          <div class="col-12 col-md-4 col-lg-3 order-md-first">
            <img src="/assets/images/screen-2.jpg" class="img-fluid d-block mx-auto mb-4" width="400">
          </div>
        </div>

        <hr class="xl">

        <p class="h2">Условия работы Snappy с фотографами:</p>
        <ol>
          <li><strong>Размещение твоего аккаунт на Snappy бесплатное.</strong> Но модераторы Snappy сами определяют кого
            размещать на площадке. Мы выбираем лучших для наших клиентов. У тебя может быть немного опыта съемок, но
            качество работ в заявленной тобой категории профессионализма (Guru, SuperPro или Агент Snappy) и умение
            общаться с клиентами (иногда мы тебе звоним) должны быть на высочайшей высоте.
          </li>
          <li><strong>Берем 25% от заказа, который Snappy нашел для тебя.</strong> Это предоплата, которую клиент вносит
            при бронировании съемки. Она гарантирует намерения клиента и остается у площадки. Остальные 75% клиент
            платит тебе после съемки.
          </li>
          <li><strong>Отслеживаем твое время ответа клиенту о подтверждении заказа съемки.</strong> Snappy берет на себя
            все расходы по привлечению клиентов, пожалуйста, отвечай им в течение 120 минут, 2 часjd (мы учитываем, что
            заказ может прийти ночью). Если заказы тебе не очень нужны или свободное время было не актуальным, мы
            отдадим их другому фотографу – это честно, клиент не может ждать.
          </li>
          <li><strong>Вовремя приходи на съемку, отдавай фото в дату, которую вы с клиентом зафиксировали.</strong> Мы
            следим за сроками сдачи фото, заранее напоминаем о датах, берем отзывы клиентов. Мы хотим, чтобы после опыта
            съемки с тобой клиенты говорили wow! и возвращались.
          </li>
        </ol>
        <p>Подробные условия работы фотографа с площадкой смотри
          <router-link :to="{name:'rules'}">здесь</router-link>
          .
        </p>

        <hr class="xl">

        <div class="row">
          <div class="col-12 col-md-8 col-lg-9 order-md-last">
            <p class="h2">Как происходит работа по заказу:</p>
            <ol>
              <li><strong>Клиент выбрал тебя.</strong> Указал место (иногда вплоть до номера дома, где он хочет
                сниматься) и время с датой. Сразу же клиент оплатил 25% стоимости съемки у тебя.
              </li>
              <li><strong>Фотографу (тебе) приходит уведомление на эл. почту о заказе. Если ты не забыл указать
                мобильный телефон, уведомление придет еще по СМС и в чат в Telegram (подключи его).</strong> Важно
                оперативно подтвердить или отменить съемку (помнишь про 120 минут, 2 часа?), чтобы клиент не остался
                разочарован и вовремя нашел себе фотографа.
              </li>
              <li><strong>Ты (фотограф) подтвердил заказ.</strong> Отлично! Клиент может уточнить у тебя детали съемки в
                переписке в заказе. Нельзя обмениваться контактами до оплаты клиентом съемки или отменять съемку и
                договариваться вне площадки. К сожалению, нам придется расстаться с таким фотографом.
              </li>
              <li><strong>Все заказы и свой календарь ты видишь в Личном кабинете фотографа.</strong> Чтобы было удобно
                вноси в календарь и те заказы, которые выполняешь не по заявкам со Snappy.
              </li>
              <li><strong>Отменить съемку фотограф может, если есть причины.</strong> Напиши их, пожалуйста, при отмене
                в комментариях.
              </li>
              <li><strong>Съемка.</strong> До даты съемки мы напомним тебе о ней. После съемки отметь статус заказа в
                Личном кабинете «Съемка завершена», укажи дату сдачи снимков, о которой вы договорились с клиентом.
                Клиент оплачивает тебе после завершения съемки 75% от стоимости, способом, о котором вы с ним
                договоритесь.
              </li>
              <li><strong>Когда дата сдачи снимков придет</strong> (заранее напомним о ней) – отправь в статусе заказа
                клиенту ссылку на снимки. Жди отзыв клиента о твоей работе!
              </li>
              <li><strong>Поблагодари клиента за отзыв (он появится на твоей страничке) и получай новые заказы!</strong>
              </li>
            </ol>
          </div>
          <div class="col-12 col-md-4 col-lg-3 order-md-first">
            <img src="/assets/images/screen-3.jpg" class="img-fluid d-block mx-auto mb-4" width="255">
          </div>
        </div>

        <hr class="xl">

        <p class="h2">Не теряй время и заказы, регистрируйся на Snappy!<br><br>
          <button class="btn btn-primary" type="button" v-on:click="$root.$emit('show-login-form', 'register-photo')">
            Зарегистрироваться
          </button>
        </p>

        <p class="h2">Snappy создан соединять клиентов и фотографов быстро</p>

        <p class="h2">Есть вопросы? Нужна помощь?</p>
        <p>Пиши нам в чат поддержки в Telegram<br>
          <a href="https://t.me/snappy_photoshoot" target="_blank" rel="nofollow">t.me/snappy_photoshoot</a></p>
        <p>Отвечаем с 10:00 до 18:00 по Москве. Вопросы после 18:00 – следующим утром</p>

        <p class="h2">Напишите нам:</p>
        <feed-back-form></feed-back-form>

      </div>
    </div>
  </div>
</template>

<script>
import FeedBackForm from "@/components/widgets/FeedBackForm";

export default {
  name: "Photographer",
  components: {FeedBackForm}
}
</script>

<style scoped>

</style>