<template>
    <div class="inner-content">
        <p class="h2">{{$t('lk.secure_area.secure')}}</p>
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>{{$t('lk.secure_area.password_old')}}</label>
                    <input type="password" class="form-control form-control-sm" :placeholder="$t('lk.secure_area.password_old_input')" v-model="oldPassword">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>{{$t('lk.secure_area.password_new')}}</label>
                    <input type="password" class="form-control form-control-sm" :placeholder="$t('lk.secure_area.password_new_input')"  v-model="newPassword">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>{{$t('lk.secure_area.password_new_2')}}</label>
                    <input type="password" class="form-control form-control-sm" :placeholder="$t('lk.secure_area.password_new_2_input')" v-model="replyNewPassword">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group form-group-nolabel">
                    <button class="btn btn-primary btn-block" v-on:click="reset">{{$t('lk.secure_area.change_password')}}</button>
                </div>
            </div>
        </div>
        <p class="text-muted">{{$t('lk.secure_area.password_info')}}</p>
        <p>{{$t('lk.secure_area.password_info_2')}}</p>
        <ul class="text-muted" v-html="$t('lk.secure_area.password_info_list')"></ul>

    </div>
</template>

<script>
    import Api from '../../service';

    let api = undefined;
export default {
    name: "Secure",
    data(){
        return{
            newPassword:'',
            oldPassword:'',
            replyNewPassword:'',
        }
    },
    methods:{
        reset(){
            if(this.newPassword !== this.replyNewPassword){
                alert('Пароли не сопадают (ошибку в дизайн внесем позже)');
                return;
            }
            let password = {
              "old_password": this.oldPassword,
              "new_password":  this.newPassword,
              "new_password_confirmation":  this.newPassword,
            };
            api
                .resetPassword(password)
                .then((res)=>{
                    this.$root.$emit('successful', 'Пароль успешно заменено');
                    this.newPassword = '';
                    this.oldPassword = '';
                    this.replyNewPassword = '';
                })
                .catch((error)=>{
                    this.$root.$emit('error', error.response.data.message);
                })
        }
    },
    mounted() {
        api = new Api();
    }
}
</script>

<style scoped>

</style>