<template>
    <div>
      <confirm-phone ref="regConfirmPhone" :modal_id="'modal-reg-confirm-phone'"></confirm-phone>
        <button class="btn btn-secondary btn-sm" type="button" v-b-modal.modal-login v-on:click="state ='register-user'">{{ $t('base.auth.sign_in') }}</button>
        <b-modal id="modal-login" centered hide-header :hide-footer="!responseError">
            <div class="modal-header">
                <p v-if="state === states.STATE_LOGIN" class="modal-title modal-brand" v-html="$t('auth.notice_1')"></p>
                <p v-if="state === states.STATE_REGISTER" class="modal-title modal-brand" v-html="$t('auth.notice_2')"></p>
                <p v-if="state === states.STATE_PHOTO_REGISTER" class="modal-title modal-brand" v-html="$t('auth.notice_3')"></p>
                <p v-if="state === states.STATE_RECOVERY_PASSWORD" class="modal-title modal-brand" v-html="$t('auth.notice_4')"></p>
                <div class="close" @click="$bvModal.hide('modal-login')"></div>
            </div>
            <div class="d-block" v-if="state === states.STATE_LOGIN">
                <div class="form-group">
                    <input v-on:keyup.enter="login" v-model="user.login" type="email" name="email" :class="{'has-error':responseError}" :placeholder="$t('auth.placeholder.login')" class="form-control form-icon icon-email">
                </div>
                <div class="form-group">
                    <input v-on:keyup.enter="login"
                           v-model="user.password"
                           :type="passwordType"
                           name="password"
                           :class="{'has-error':responseError}"
                           :placeholder="$t('auth.placeholder.password')"
                           class="form-control form-icon icon-password">
                    <div class="show-password pointer" v-on:click="toggleType()"></div>
                </div>
                <div class="form-group">
                    <b-button variant="primary" size="lg" class="btn-uppercase" block v-on:click="login()">{{ $t('base.auth.sign_in') }}</b-button>
                </div>
                <div class="form-group social-auth">
                    <p>{{ $t('base.auth.or') }}</p>
                    <social></social>
                </div>
                <div class="text-center modal-links">
                    Нет аккаунта? <a href="#" v-on:click="state = states.STATE_REGISTER">{{ $t('base.auth.register_text') }}</a><br>
                    {{ $t('base.auth.forget_password') }} <a href="#" v-on:click="state ='recovery'">{{ $t('base.auth.restore_password') }}</a>?
                </div>
            </div>
            <div class="form-group register-switcher" v-if="state === states.STATE_REGISTER || state === states.STATE_PHOTO_REGISTER">
                <div class="register-switcher-prepend">Я</div>
                <div class="custom-control custom-switch-select" :class="{'second':state === states.STATE_PHOTO_REGISTER}" v-on:click="toggleRegister()">
                    <label class="custom-control-label">{{ $t('base.auth.user') }}</label>
                    <label class="custom-control-label">{{ $t('base.auth.photographer') }}</label>
                </div>
            </div>
            <div class="d-block text-center" v-if="state === states.STATE_REGISTER || state === states.STATE_PHOTO_REGISTER">

                <!--div class="swich-registration icon-photo"><a href="#" v-on:click="state ='register-photo'">{{$t('base.auth.register')}}</a> {{$t('base.auth.as_photographer')}}</div-->
                <div class="form-group">
                    <input v-on:keyup.enter="register" type="text" name="name" :placeholder="$t('auth.placeholder.name')" :class="{'has-error':registerError.name}" v-model="new_user.name" class="form-control form-icon icon-account">
                </div>
                <div class="form-group">
                    <input v-on:keyup.enter="register" type="email" name="email" :placeholder="$t('auth.placeholder.login')" :class="{'has-error':registerError.email}" v-model="new_user.email" class="form-control form-icon icon-email">
                </div>
              <div class="form-group">
                <vue-tel-input ref="tel" wrapperClasses="form-control-phone"  :class="{'has-error':registerError.phone}"  inputClasses="form-control form-control" v-model="new_user.phone" :valid-characters-only="true" :dynamicPlaceholder="true"></vue-tel-input>
              </div>
                <!--div class="form-group">
                    <input v-on:keyup.enter="register" type="text" :placeholder="$t('auth.placeholder.phone')" :class="{'has-error':registerError.phone}" v-model="new_user.phone" class="form-control form-icon icon-phone">
                </div-->
                <div class="form-group">
                    <div class="form-group-password">
                        <input v-on:keyup.enter="register" :type="passwordType" name="password" :placeholder="$t('auth.placeholder.password')" :class="{'has-error':registerError.password}" v-model="new_user.password" class="form-control form-icon icon-password">
                        <div class="show-password" v-on:click="toggleType()"></div>
                    </div>
                    <small id="passwordHelpBlock" class="form-text text-muted">{{ $t('auth.pass_hint') }}</small>
                </div>
                <div class="form-group">
                    <b-button variant="primary" size="lg" class="btn-uppercase" v-on:click="register()" block>{{ $t('base.auth.register') }}</b-button>
                </div>
                <div class="form-group social-auth">
                    <p>{{ $t('base.auth.or') }}</p>
                    <social></social>
                </div>
                <div class="form-group text-left">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" checked="" name="checkbox" id="checkbox-confirm-register-client" class="custom-control-input">
                        <label for="checkbox-confirm-register-client" class="custom-control-label"><small>Осуществляя регистрацию, я выражаю согласие с Пользовательским соглашением и Политикой конфиденциальности</small></label>
                    </div>
                </div>
                <div class="text-center modal-links">
                    Есть аккаунт? <a href="#" v-on:click="state ='auth'">Войдите</a>
                </div>
            </div>

            <div class="d-block text-center" v-if="state === states.STATE_RECOVERY_PASSWORD">
                <p>{{ $t('base.auth.restore_password_text') }}</p>
                <div class="form-group">
                    <input type="email" name="email" v-model="recoveryEmail" :placeholder="$t('auth.placeholder.login')" :class="{'has-error':responseError}" class="form-control form-icon icon-email">
                </div>
                <div class="form-group">
                    <b-button variant="primary" size="lg" class="btn-uppercase" block v-on:click="recovery">{{ $t('base.auth.recovery') }}</b-button>
                </div>
                <div class="modal-links">
                    <a href="#" class="back" v-on:click="state ='auth'">{{ $t('base.auth.back_to_login') }}</a>
                </div>
            </div>

            <template v-slot:modal-footer v-if="responseError">
                <b-toast title="" static no-auto-hide :visible="true" variant="danger">
                    {{ responseError }}
                </b-toast>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {BModal} from 'bootstrap-vue';
import Api from '../services';
import Social from "../auth/Social";
import ConfirmPhone from "@/components/lk/components/widget/ConfirmPhone";

let api = new Api();

const
        STATE_LOGIN = 'auth',
        STATE_REGISTER = 'register-user',
        STATE_PHOTO_REGISTER = 'register-photo',
        STATE_RECOVERY_PASSWORD = 'recovery';
export default {
    name: 'Login',
    data() {
        return {
            states: {
                STATE_LOGIN: STATE_LOGIN,
                STATE_REGISTER: STATE_REGISTER,
                STATE_PHOTO_REGISTER: STATE_PHOTO_REGISTER,
                STATE_RECOVERY_PASSWORD: STATE_RECOVERY_PASSWORD,
            },
            passwordType: 'password',
            remember_me: false,
            state: STATE_LOGIN,
            responseError: false,
            registerError: {
                email: false,
                phone: false,
                password: false
            },
            user: {
                login: '',
                password: ''
            },
            recoveryEmail: '',
            new_user: {}

        }
    },
    components: {
      ConfirmPhone,
        Social,
        BModal
    },
    watch: {
        state() {
            this.responseError = false;
            this.recoveryEmail = '';
            this.passwordType = 'password';
            this.new_user = {
                role: this.state === STATE_PHOTO_REGISTER ? 'photographer' : 'client',
            };
            this.registerError = {
                email: false,
                phone: false,
                password: false
            };
        }
    },
    mounted() {
      this.$root.$on('show-login-form',(state)=>{
        this.state = state;
        this.$bvModal.show('modal-login');
      });
    },
    methods: {
        toggleType() {
            this.passwordType = this.passwordType === 'text' ? 'password' : 'text'
        },
        toggleRegister() {
            this.state = this.state === this.states.STATE_REGISTER ? this.states.STATE_PHOTO_REGISTER : this.states.STATE_REGISTER;
        },
        recovery() {
            this.responseError = false;
            if (!this.recoveryEmail || this.recoveryEmail === '') {
                this.responseError = 'Не должен быть пустым';
                return;
            }
            api
                    .recovery(this.recoveryEmail)
                    .then(
                            (res) => {
                                this.recoveryEmail = '';
                                this.$root.$emit('successful', 'На почту отправленно письмо с для сброса пароля!');
                            }
                    )
                    .catch((error) => {
                        this.responseError = error.response.data.data.email;
                    });
        },
        register() {
          this.$root.$emit('press_vk_button', 'click-button-find', 36514686);
            this.responseError = false;
            this.registerError = {
                email: false,
                phone: false,
                password: false
            };
          api
              .register(this.new_user)
              .then(
                  (res) => {
	                  if( this.$metrika ) {
					      this.$metrika.reachGoal(this.state === STATE_PHOTO_REGISTER ? 'Registration Photograph' : 'Registration Client');
					  }
                    this.registerError = {
                      email: false,
                      phone: false,
                      password: false
                    };
                    this.state = STATE_LOGIN;
                    this.$root.$emit('successful', 'На почту отправленно письмо с подтверждением!');

                    this.$forceUpdate();
                    this.$refs.regConfirmPhone.show(
                        {
                          timer: 120,
                          phone_key:res.data.data.phone_key
                        }
                    );
                  }
              )
              .catch((error) => {
                // this.responseError = error.response.data.message;
                for (let [key] of Object.entries(error.response.data.data)) {
                  // if(key === 'email'){
                  //     this.$t('register.auth_error')
                  // }
                  this.registerError[key] = true;
                }
              });
        },

        login: function () {
          this.$root.$emit('press_vk_button', 'click-button-find', 36514616);
            this.responseError = false;
            let self = this;
            this.$auth.login({
                body: this.user,
                redirect: false,
                rememberMe: this.remember_me,
                fetchUser: true,
                success() {
	                if( this.$metrika ) {
	                	this.$metrika.reachGoal('Enter');
	                }
                    //TODO переделать
                    this.$root.$emit('auth');
                    this.$store.commit('user/auth', self.$auth.token());
                    self.responseError = false;
                },
                error(error) {
                    // this.responseError = error.data.message;
                    // Неверно введен емейл или пароль, Повторите попытку или воспользуйтесь «Восстановлением пароля»
                    self.responseError = this.$t('auth.auth_error');
                }
            });
        }
    }
};
</script>

<style scoped>

</style>