import { render, staticRenderFns } from "./OrderInfoModal.vue?vue&type=template&id=77f92030&scoped=true&"
import script from "./OrderInfoModal.vue?vue&type=script&lang=js&"
export * from "./OrderInfoModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f92030",
  null
  
)

export default component.exports